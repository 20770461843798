.mySwiper .mySwiper2 {
  width: 100%;
  height: 100%;
}

.mySwiper .mySwiper2 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper .mySwiper2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper .swiper-slide img {
  width: 100%;
  height: 60px;
}

/* .swiper { */
.mySwiper .mySwiper2 {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.mySwiper .mySwiper2 .swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 400px;
  width: 100%;
}

.mySwiper2 .swiper-button-next::after,
.mySwiper2 .swiper-button-prev::after {
  font-size: 24px;
  color: #f5a100;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 1px ridge #f5a100;
  border-radius: 5px;
  padding: 5px;
}

.cart-card {
  border: 0.5px solid;
  border-radius: 4px;
  padding: 12px;
}
